import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import toast from 'react-hot-toast'
import { claimReward, getDailyCheckin } from '../api'
import { useSelector } from 'react-redux'
import { Statistic } from 'antd'
import { ContractContext } from '../context/ContractContext'
import ConnectWallet from '../components/ConnectWallet'
const { Countdown } = Statistic;

const abc = ["one", "two", "three", "four", "five", "six", "seven"]
const DailyCheckin = () => {
    const [data, setData] = useState({});
    const [cardData, setCardData] = useState({});
    const [timer, setTimer] = useState(0);
    const [isAvailable, setIsAvailable] = useState(false);
    const [isFetched, setIsFetched] = useState(false)
    const { setFetchUser } = useContext(ContractContext);
    // const {contract,} = useSelector(state => state.user)
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (data) {
            let createData = {};
            let isA = false
            abc.map(item => {
                if (data[item]?.status == "pending") {
                    createData[item] = "Upcoming Card"
                }
                if (data[item]?.status == "claimed") {
                    createData[item] = "Claimed Card"
                }
                if (data[item]?.status == "banned") {
                    createData[item] = "Banned"
                }
                if (data[item]?.status == "available") {
                    createData[item] = "Active Card"
                    isA = true
                }
            })
            setCardData(createData)
            setIsAvailable(isA)
        }
    }, [data])

    const getData = async () => {
        try {
            const { data } = await getDailyCheckin()
            setData(data?.data)
            console.log(data?.time);
            setTimer(Date.now() + data?.time)
        } catch (err) {
            toast.error(err?.response?.data?.message || err.message)
        }
    }
    useEffect(() => {
        try {
            getData();
            setTimeout(() => {
                setIsFetched(true)
            }, 2000);
        } catch (err) { }
    }, [])



    const claim = async () => {
        try {
            const { data } = await claimReward();
            if (data.success) {
                toast.success(data.message);
                setData(data.data);
                setTimer(Date.now() + data?.time);
                setFetchUser(prev => prev + 5);
                getData();
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message || err.message)
            }
        }
    }
    return (
        <>
            <Navbar />
            <div className="mt-lg-5 mt-3">
                <div className="container" style={{ height: "80vh" }}>
                    {
                        user.walletAddress ?
                            <div className="popUpMainBg">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-10 d-flex flex-column justify-content-center align-items-start">
                                        <h3 className="cl-M fs-theme">Daily Reward</h3>
                                        <span className="f-14 cl-t">Come back every day to get better rewards!</span>
                                    </div>
                                    {/* <div className="col-2 d-flex justify-content-center align-items-center">
                                <a href="#" className="ms-auto"><i className="far fa-times checkclose cl-t"></i></a>
                            </div> */}
                                </div>
                                <div className="row my-4 my-md-5">
                                    <div className="cusGrid">
                                        <img src={`/images/Daily_Checkin/${cardData?.one || "Claimed Card"} - Day 1.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.two || "Claimed Card"} - Day 2.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.three || "Claimed Card"} - Day 3.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.four || "Claimed Card"} - Day 4.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.five || "Claimed Card"} - Day 5.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.six || "Claimed Card"} - Day 6.png`} className="img-fluid weeklyCard" draggable="false" />
                                        <img src={`/images/Daily_Checkin/${cardData?.seven || "Claimed Card"} - Day 7.png`} className="img-fluid weeklyCard" draggable="false" />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-8 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center w-100">
                                        <div></div>
                                        {
                                            isAvailable ?
                                                <a href="#" className="ms-md-auto order-0 order-md-1" onClick={claim}><div className="BtnClaim">Claim</div></a> : <div className='BtnClaim d-flex align-items-center' style={{cursor:"not-allowed",opacity:"0.7"}} aria-disabled>
                                                    <i className="far fa-stopwatch cl-Y me-2 me-lg-3 h4 mb-0"></i>
                                                    <Countdown value={timer} onFinish={getData} className='cl-gt checkinTimer order-1 order-md-0 mt-3 mt-md-0' />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div> : isFetched ? <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                                <h2 className='mb-5'>You Need To Connect Wallet to Claim Chekin</h2>
                                <ConnectWallet />
                            </div> : ""
                    }
                </div>
                {/* <div className="checkInblackBG checkInblackBGActive"></div> */}
            </div>
        </>
    )
}

export default DailyCheckin