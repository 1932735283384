import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { remainSpins, spin } from '../../api';
import toast from 'react-hot-toast';
import { Modal, Statistic } from 'antd';
import Web3 from 'web3';
import { ContractContext } from '../../context/ContractContext';
import CountUp from 'react-countup';
const web3 = new Web3(window.ethereum);

const imagesObj = {
    0: { image: "1_Options_Gift", message: "", buttonText: "Claim Now", isMessage: true },
    1: { image: "Better_Luck_Next_Time", message: "Opps! You can try again", buttonText: "Spin Again", isMessage: false },
    2: { image: "2x", message: "Yeh! got 2x !!", buttonText: "Claim Now", isMessage: true },
    3: { image: "Free_NFT_Mint", message: "Yeh! got Free Mint !!", buttonText: "Claim Now", isMessage: false },
    4: { image: "1_Options_Gift", message: "", buttonText: "Claim Now", isMessage: true },
    5: { image: "5x", message: "", buttonText: "Claim Now", isMessage: true },
    6: { image: "Whitelist", message: "", buttonText: "Claim Now", isMessage: true },
    7: { image: "Free_Spin", message: "Got 1 More Spin", buttonText: "Let's Spin", isMessage: false }
}
const Spinner = () => {
    const numElements = 9; // Number of elements
    const initialSpinwheel = Array.from({ length: numElements }, (_, i) => i === 2 ? 'active' : false);
    const [betAmount, setBetAmount] = useState(0)
    const [spinwheel, setSpinwheel] = useState(initialSpinwheel);
    const [activeIndex, setActiveIndex] = useState(0);
    const [roundsCompleted, setRoundsCompleted] = useState(0);
    const [targetIndex, setTargetIndex] = useState(null);
    const [intervalStatus, setIntervalStatus] = useState(false);
    const [isRewardScreen, setIsRewardScreen] = useState(false);
    const [isRewardGift, setIsRewardGift] = useState(false);
    const { contract, setFetchUser } = useContext(ContractContext);
    const [spinExists, setSpinExists] = useState(0);
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        let interval = setInterval(() => {
            if (intervalStatus) {
                setSpinwheel(prevSpinwheel =>
                    prevSpinwheel.map((item, index) => index === activeIndex ? 'active' : false)
                );
                setActiveIndex(prevIndex => {
                    const nextIndex = (prevIndex + 1) % numElements;
                    if (nextIndex === 0) {
                        setRoundsCompleted(prevRounds => prevRounds + 1);
                    }
                    // console.log(nextIndex == targetIndex);
                    // console.log(targetIndex);
                    if (roundsCompleted >= 3 && (nextIndex) == targetIndex + 1) {
                        setIntervalStatus(false)
                        console.log(nextIndex)
                        if (targetIndex == 0 || targetIndex == 4) {
                            setIsRewardGift(true)
                        } else {
                            setIsRewardScreen(true)
                        }
                        // Stop the interval when reaching the target index
                    } else if (roundsCompleted >= 3 && targetIndex == 7) {
                        if ((nextIndex) >= targetIndex + 1) {
                            setIntervalStatus(false)
                            setIsRewardScreen(true)
                        }
                    }
                    return nextIndex;
                });
            }
        }, 100);

        return () => clearInterval(interval);
    }, [activeIndex, roundsCompleted, targetIndex, numElements, intervalStatus]);

    const spinHandler = async () => {
        try {
            if (betAmount >= 3000) {
                if (user?.points < betAmount) {
                    toast.error('Insufficient points')
                    return
                }
                const { data } = await spin({ amount: betAmount });
                setRoundsCompleted(0); setIntervalStatus(true);
                setTargetIndex(data?.data);
                // setTargetIndex(0);
            } else {
                toast.error('Min Bet limit is 3000')
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || err.message)
        }
    }
    const handleClaim = async () => {
        try {
            if (targetIndex == 3) {
                await freemintContract();
            }
            if (targetIndex == 7) {
                await spinHandler();
                return;
            }
            setBetAmount("")
            setIsRewardScreen(false);
            imagesObj[targetIndex]?.isMessage && toast.success("Reward Claimed Successfully")
            setFetchUser(prev => prev + 1)
        } catch (err) {
            toast.error(err.message)
        }
    }

    const freemintContract = async () => {
        if (user.points < 50000) {
            toast.error("You have not enough points to mint")
            return;
        }
        try {
            const { signature, n } = getSignature();
            // console.log(signature);
            const data = await contract.methods.freeMintPassCost().call();
            console.log(data.toString());
            console.log(n, signature);
            await contract.methods.freeMintPass(n, signature)
                .send({ to: contractAddress, value: data.toString(), from: user.walletAddress })
                .once('error', (err) => {
                    toast.error(err.message);
                })
                .then(r => {
                    toast.success("Reward Claimed Successfully")
                })
        } catch (err) { toast.error(err.message) }
    }
    const generateRandomUnit256String = () => {
        const web3 = new Web3();
        const randomHex = web3.utils.randomHex(32); // 32 bytes = 256 bits
        return randomHex;
    };
    const getSignature = () => {
        const n = generateRandomUnit256String();
        // setNounce(n)
        const message = web3.utils.soliditySha3(user.walletAddress, n);
        const { signature } = web3.eth.accounts.sign(message, process.env.REACT_APP_UNIQUE_HASH);
        return { signature, n };
    }

    const handleGiftBox = () => {
        setTargetIndex(6)
        setIsRewardScreen(true)
        setIsRewardGift(false)
    }
    const getRemainSpins = async () => {
        try {
            const { data } = await remainSpins()
            setSpinExists(data?.data)
        } catch (err) { }
    }
    useEffect(() => {
        getRemainSpins();
    }, [targetIndex, user])

    return (
        <>
            <section className="mrg_tb">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-6 position-relative d-flex justify-content-center align-items-center mrg_t "
                        >

                            <div className="row mainPaddspin">

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionGift">
                                        <img src="/images/1_Options_Gift.png" className="img-fluid"
                                            alt="Mystery Box of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[0] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionTEXT">
                                        <img src="/images/Better_Luck_Next_Time.png" className="img-fluid"
                                            alt="Better Luck Next Time Option of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[1] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionGift">
                                        <img src="/images/2x.png" className="img-fluid" alt="2x Option of ZK Realm Spinner"
                                            draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[2] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionTEXT">
                                        <img src="/images/Free_Spin.png" className="img-fluid"
                                            alt="Free Spin Option of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[7] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>


                                <div className="col-4 p-0">
                                    <img src="/images/Center_Button_of_Spinner.png" className="img-fluid w-100" draggable="false"
                                        alt="Center Button of Spinner Fot ZK Realm Spinner" />
                                </div>


                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionTEXT">
                                        <img src="/images/Free_NFT_Mint.png" className="img-fluid"
                                            alt="Free NFT Mint Option of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[3] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG" draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionTEXT">
                                        <img src="/images/Whitelist.png" className="img-fluid"
                                            alt="Whitelist Option of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[6] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionGift">
                                        <img src="/images/5x.png" className="img-fluid" alt="5x Option of ZK Realm Spinner"
                                            draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[5] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                                <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                    <div className="cusPaddOptionGift">
                                        <img src="/images/1_Options_Gift.png" className="img-fluid"
                                            alt="Mystery Box of ZK Realm Spinner" draggable="false" />
                                    </div>
                                    <img src={`/images/${spinwheel[4] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                        draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                                </div>

                            </div>

                            <img src="/images/Main_Frame_of_Spinner.png" className="img-fluid h-100 MainBGspin" draggable="false"
                                alt="Main Background Frame of ZK Realm Spinner" />
                            <img src="/images/Spin_Decor.png" className="img-fluid px-auto SpinDecor" draggable="false"
                                alt="Spinner Decoration images of ZK Realm Spinner" />
                        </div>

                        <div className="col-md-6 mt-4 mt-md-0">
                            <h5 className="ms-1 mb-2">Remaining Spins For Today : {spinExists}</h5>
                            <div className="totalPoints "><span
                                className="fs-theme cl-gt pe-lg-3 headTpoints">Total Points :</span> {user?.points || "00"}</div>
                            <input type="number" readOnly={intervalStatus} placeholder="Enter your spin amount here" value={betAmount}
                                className="cusInput w-100 my-3" data-wow-delay="1.4s" onChange={e => setBetAmount(e.target.value)} />
                            {
                                !intervalStatus &&
                                <a href="#" className="" data-wow-delay="1.6s" >
                                    <div className="btnZkboom" onClick={e => spinHandler()}>ZK BOOM</div>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Modal open={isRewardGift} footer={""} closable={false}>
                <div className="d-flex flex-column justify-content-center align-items-center" onClick={e => setIsRewardScreen(true)}>
                    <div>
                        <img src={`/images/1_Options_Gift.png`} draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                    </div>
                    <button className="btnZkboom py-2 mt-3" onClick={e => handleGiftBox()}>Open Now</button>
                </div>
            </Modal>
            <Modal open={isRewardScreen} footer={""} closable={false}>
                <div className="d-flex flex-column justify-content-center align-items-center" onClick={e => setIsRewardScreen(false)}>
                    <div className='p-4'>
                        <img src={`/images/${imagesObj[targetIndex]?.image}.png`} draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                    </div>
                    <span class="fs-theme f-35 cl-gt ClickMe">{imagesObj[targetIndex]?.message}</span>
                    <button className="btnZkboom py-2 mt-3" onClick={e => handleClaim()}>{imagesObj[targetIndex]?.buttonText}</button>
                </div>
            </Modal>
        </>
    )
}

export default Spinner