import React, { useState } from 'react'

const AboutUs = () => {
    const [isReadMore, setIsReadMore] = useState(false)
    return (
        <section id="AboutUs">
            <div className="container mrg_b">
                <div className="row wow flash">
                    <div className="heading d-flex justify-content-center align-items-center mrg_tb">
                        <div className="right_line">
                            <div className="Line200 RGreen"></div>
                            <div className="Line100 ROrg ms-auto"></div>
                        </div>
                        <h2>ABOUT US</h2>
                        <div className="left_line">
                            <div className="Line200 LGreen"></div>
                            <div className="Line100 LOrg"></div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-lg-6 cusZindex wow slideInDown">
                        <img src="/images/aboutUsImg.png" alt="About Of ZK Realm" draggable="false" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 cusZindex wow fadeInLeft" data-wow-delay="1s">
                        <div className="cl-t lh-theme pb-3">
                            Welcome to <span className="cl-M">ZK Realm</span>, a community Driven NFT project on the ZKSync blockchain. With a total supply of 5555 NFTs, our realm blends artistry and innovation seamlessly. At ZK Realm, authenticity reigns supreme - no fack promises, just genuine creativity. As we embark on this journey, we extend a hand to all, offering free minting and whitelist opportunities. Join us in shaping the future of art and gamified rewards, where every NFT holds the potential to captivate and inspire.
                        </div>
                        {
                            isReadMore &&
                            <div className="cl-t lh-theme pb-3">
                                At <span className="cl-M">ZK REALM</span> , we're pioneering a new era of NFT experiences on the zkSync blockchain. With a relentless focus on innovation and user engagement, we've crafted a platform that combines the excitement of NFTs with gamified rewards to create an immersive and rewarding experience for our community.
                            </div>
                        }
                        {
                            !isReadMore ?
                                <div style={{ cursor: 'pointer' }} onClick={e => setIsReadMore(true)}><div className="cusBtnTwo mt-3">Read More</div></div> : ""
                        }
                    </div>
                </div>
                {isReadMore && <div style={{position:"relative",zIndex:"99"}}><div className="cl-t lh-theme pb-3">
                    Our journey began with a vision to revolutionize the way people interact with NFTs, transcending traditional boundaries and bringing forth a new wave of creativity and opportunity. Built on the secure and efficient zkSync blockchain, our platform offers users a seamless and frictionless experience, empowering them to explore, collect, and interact with NFTs like never before.
                </div>
                    <div className="cl-t lh-theme pb-3">
                        Our roadmap is guided by a commitment to user satisfaction and community empowerment. Through features like tasks, spins, referrals, and point redemptions, we strive to reward our users for their engagement and loyalty, fostering a vibrant and dynamic ecosystem where everyone has the opportunity to thrive.
                    </div>
                    <div className="cl-t lh-theme pb-3">
                        But ZK REALM is more than just a platform—it's a community. We're dedicated to fostering a welcoming and inclusive environment where users from all walks of life can come together to share their passion for NFTs, connect with like-minded individuals, and embark on exciting adventures together.
                    </div>
                    <div className="cl-t lh-theme pb-3">
                        Join us on this journey as we redefine the future of NFTs. Together, we'll unlock new possibilities, push the boundaries of imagination, and build a world where everyone has the chance to be a part of something extraordinary. Welcome to ZK REALM —where the adventure begins.
                    </div>
                </div>
                }
            </div>
        </section >
    )
}

export default AboutUs