import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Privacy = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="container">
                <div className="cl-t lh-theme pb-3">
                    Privacy Policy for zk realm

                    Effective Date: 12/07/2024
                    <br />
                    1. Introduction
                    <br />
                    Welcome to zk realm! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you interact with our platform and participate in our NFT project, ZRUNDEADS.
                    <br />
                    2. Information We Collect
                    <br />
                    2.1 Personal Information:

                    Wallet Address: Collected to facilitate NFT transactions and reward distribution.
                    <br />
                    2.2 Non-Personal Information:

                    Usage Data: Includes information about your interactions with our platform, such as daily check-ins, tasks completed, games played, and referral activity.
                    Technical Data: Includes your IP address, browser type, device information, and operating system.
                    <br />
                    3. How We Use Your Information
                    <br />
                    We use your information to:

                    Facilitate and enhance your experience on our platform.
                    Communicate with you about updates, promotions, and events related to zk realm.
                    Improve our platform's functionality and user experience.
                    Ensure the security and integrity of our platform.
                    <br />
                    4. Sharing Your Information
                    <br />
                    We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:

                    Service Providers: We may share your information with third-party service providers who assist us in operating our platform and conducting our business.
                    Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                    <br />
                    5. Security of Your Information
                    <br />
                    We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, or missuse. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.

                    <br />
                    6. Changes to This Privacy Policy
                    <br />
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. You are advised to review this Privacy Policy periodically for any changes.
                    <br />
                    7. Contact Us
                    <br />
                    If you have any questions or concerns about this Privacy Policy, please contact us at Discord.

                    By using our platform, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
                    <br />
                    Risk Disclosure :

                    ZKRealm is a gamified experimental project built on zkSync blockchain. While we strive to provide an engaging and rewarding experience for our users, it's important to acknowledge the inherent risks associated with NFTs:
                    <br />
                    1. Market Volatility: NFT prices can be highly volatile and may fluctuate significantly over short periods. Users should be aware that the value of NFTs can rise or fall unpredictably.
                    <br />
                    2. No Financial Advice: ZKRealm does not provide financial advice. Users should conduct their own research and consider their risk tolerance before participating in NFT transactions.
                    <br />
                    3. Technology Risks: As an experimental project, ZKRealm may encounter technical issues, bugs, or vulnerabilities that could affect the functionality or security of NFTs.
                    <br />
                    4. Counterparty Risks: Engaging in transactions with unknown or unverified parties carries inherent risks. Users should exercise caution when trading NFTs and only interact with trusted counterparts.
                    <br />
                    5. Regulatory Uncertainty: The regulatory landscape surrounding NFTs is evolving and may impact the legality, use, and trading of NFTs. Users should stay informed about relevant regulations and compliance requirements.
                    <br />
                    6. Ownership and Copyright Issues: While owning an NFT provides ownership rights to a digital asset, it may not necessarily grant copyright or intellectual property rights. Users should be mindful of potential legal and copyright implications associated with NFT ownership.
                    <br />
                    7. Loss of Access: Users may lose access to their NFTs due to factors such as forgotten passwords, hardware failures, or platform shutdowns. It's essential to take precautions to safeguard access to NFTs and store private keys securely.
                    <br />
                    By using ZKRealm, users acknowledge and accept these risks. ZKRealm is not responsible for any losses or damages incurred as a result of participating in NFT transactions or using the platform.
                    <br />
                    This revised risk disclosure statement provides a comprehensive overview of the risks associated with NFTs and emphasizes the experimental nature of our project.
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Privacy