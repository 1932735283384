import React from 'react'

const Roadmap = () => {
    return (
        <section id="ROADMAP" className="position-relative pad_t">

            <img src="/images/Right-Spider-Net.png" alt="Horror Spider Net imafge for decorate ZK Realm website" className="img-fluid RightSpiderNet" draggable="false"/>
                <img src="/images/Left-Spider-Net.png" alt="Horror Spider Net imafge for decorate ZK Realm website" className="img-fluid LeftSpiderNet" draggable="false"/>

                    <div className="container">

                        {/* <!-- ============  Heading  ============ --> */}
                        <div className="row wow flash mrg_b">
                            <div className="heading d-flex justify-content-center align-items-center">
                                <div className="right_line">
                                    <div className="Line200 RGreen"></div>
                                    <div className="Line100 ROrg ms-auto"></div>
                                </div>
                                <h2>ROADMAP</h2>
                                <div className="left_line">
                                    <div className="Line200 LGreen"></div>
                                    <div className="Line100 LOrg"></div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- ============  Heading  ============ --> */}
                        <div className="row">

                            <div className="timeline">
                                <ul>
                                    <li className="wow fadeInLeft">
                                        <div className="content">
                                            <h5 className="cl-M">Initial Setup and Platform Development</h5>
                                            <p className="f-14">
                                {`-> Set up the project infrastructure on zkSync blockchain.`}<br />
                                {`-> Develop the core functionalities of the platform, including user authentication and capabilities.`}
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>1</h4>
                                        </div>
                                    </li>

                                    <li className="wow fadeInRight">
                                        <div className="content">
                                            <h5 className="cl-V">Community Building Phase</h5>
                                            <p className="f-14">
                                {`-> Launch social media channels (Discord, Twitter,ETC).`}<br />
                                {`-> Encourage users to join Discord and follow on Twitter.`}<br />
                                {`-> Initiate engagement campaigns to build a strong community.`}
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>2</h4>
                                        </div>
                                    </li>

                                    <li className="wow fadeInLeft">
                                        <div className="content">
                                            <h5 className="cl-M">Gamification Features Implementation</h5>
                                            <p className="f-14">
                                {`-> Integrate Daily check-in feature`}<br />
                                {`-> Integrate Task`}<br />
                                {`-> Integrate Refer & Earn`}<br />
                                {`-> Integrate Bull and Bear GAME`}<br />
                                {`-> Integrate Leaderboard`}
                                {`-> Integrate spin feature allowing users to earn rewards like free mint, Lucky Box , WL , 2x points, and 5x points and much more`}<br />
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>3</h4>
                                        </div>
                                    </li>

                                    <li className="wow fadeInRight">
                                        <div className="content">
                                            <h5 className="cl-V">Referral Program Setup</h5>
                                            <p className="f-14">
                                                {`-> Develop a referral program where users can earn points for successful referrals.`}<br />
                                                {`-> Provide users with unique referral links to track successful referrals.`}
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>4</h4>
                                        </div>
                                    </li>

                                    <li className="wow fadeInLeft">
                                        <div className="content">
                                            <h5 className="cl-M">Point Redemption System</h5>
                                            <p className="f-14">
                                {`-> Introduce the point redemption system, allowing users to redeem points for various rewards like WL (Whitelist) access , Discord Roles ,free mint opportunities and much more...`}
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>5</h4>
                                        </div>
                                    </li>

                                    <li className="wow fadeInRight">
                                        <div className="content">
                                            <h5 className="cl-V">Launch and Community Engagement</h5>
                                            <p className="f-14">
                                                {`-> Officially launch of the ZKUNDEADS on zkSync blockchain.`}<br />
                                                {`-> Continue to engage with the community through social media, Discord, and other channels.`}
                                                <br />
                                                {`-> Monitor platform performance and user engagement metrics, making adjustments as necessary.`}
                                            </p>
                                        </div>
                                        <div className="time">
                                            <h4>6</h4>
                                        </div>
                                    </li>

                                    <div style={{clear:'both'}}></div>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="circleAnimation4"><div className="MainHvr"><div className="UnderMainHvr"><div className="circle circlethree"></div></div></div></div>
                </section>
                )
}

                export default Roadmap