import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Terms = () => {
    return (
        <>
            <Navbar></Navbar>
            <div className="container">
                <div className="cl-t lh-theme pb-3">
                    1. Introduction
                    <br />
                    Welcome to zk realm! These Terms and Conditions ("Terms") govern your use of our platform and participation in our experimental NFT project, ZRUNDEADS. By accessing or using our platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our platform.
                    <br />

                    2. Eligibility
                    <br />
                    You must be at least 18 years old to use our platform and participate in our NFT project. By using our platform, you represent and warrant that you meet this age requirement.
                    <br />
                    3. User Accounts
                    <br />
                    3.1 Wallet Address:
                    To participate in our platform activities and receive rewards, you must connect a compatible cryptocurrency wallet. You are responsible for maintaining the security of your wallet and any associated private keys.
                    <br />
                    3.2 Account Activity:
                    You are responsible for all activities that occur under your wallet address. If you suspect any unauthorized use of your account, you must notify us immediately.
                    <br />
                    4. Platform Activities
                    <br />
                    4.1 Earning ZR Points:
                    Users can earn ZR points by participating in daily check-ins, completing tasks, playing games (such as spin, bull, and bear games), and using the refer-and-earn option.
                    <br />
                    4.2 Redeeming ZR Points:
                    ZR points can be redeemed for rewards such as free mint passes and whitelist spots. The availability of these rewards is subject to change at our discretion.
                    <br />
                    5. Intellectual Property
                    <br />
                    All content on the zk realm platform, including but not limited to text, graphics, logos, and software, is the property of zk realm or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from our platform without our prior written permission.
                    <br />
                    6. Prohibited Activities
                    <br />
                    You agree not to engage in any of the following activities:
                    <br />
                    ‌ ·Using the platform for any unlawful purpose.<br />
                    ‌ ·Attempting to interfere with the proper functioning of the platform.<br />
                    ‌ ·Engaging in any activity that could harm the platform or its users.<br />
                    ‌ ·Impersonating any person or entity or misrepresenting your affiliation with any person or entity.
                    <br />
                    7. Termination
                    <br />
                    We reserve the right to terminate or suspend your access to the platform at any time, with or without cause or notice, including if we believe you have violated these Terms.
                    <br />
                    8. Disclaimers
                    <br />
                    The zk realm platform is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the platform or its content. We do not warrant that the platform will be error-free or uninterrupted.
                    <br />
                    9. Limitation of Liability
                    <br />
                    To the fullest extent permitted by law, zk realm and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform, even if we have been advised of the possibility of such damages.
                    <br />
                    10. Changes to These Terms
                    <br />
                    We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our platform. You are advised to review these Terms periodically for any changes. Your continued use of the platform after the effective date of any changes constitutes your acceptance of the new Terms.
                    <br />
                    11. Governing Law
                    <br />
                    These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
                    <br />
                    12. Contact Us
                    <br />
                    If you have any questions or concerns about these Terms, please contact us at Discord.
                    <br />
                    By using our platform, you acknowledge that you have read and understood these Terms and agree to be bound by them.
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Terms