import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Spin from './pages/Spin'
import { Toaster } from 'react-hot-toast'
import LeaderBoard from './pages/LeaderBoard'
import PointRedeem from './pages/PointRedeem'
import ReferAndEarn from './pages/ReferAndEarn'
import Task from './pages/task/Task'
import DailyCheckin from './pages/DailyCheckin'
import UpdateComponent from './components/UpdateComponent'
import Quiz from './pages/task/components/Quiz'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'


const App = () => {
  return (
    <BrowserRouter>
      <Toaster position='top-right'></Toaster>
      <UpdateComponent></UpdateComponent>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/terms' element={<Terms />}></Route>
        <Route path='/privacy' element={<Privacy />}></Route>
        <Route path="/dailyCheckin" element={<DailyCheckin />}></Route>
        <Route path='/spin' element={<Spin />}></Route>
        <Route path='/leaderboard' element={<LeaderBoard />}></Route>
        <Route path='/pointredeem' element={<PointRedeem />}></Route>
        <Route path='/refer' element={<ReferAndEarn />}></Route>
        <Route path='/task' element={<Task />}></Route>
        <Route path='/quiz' element={<Quiz />}></Route>
      </Routes>
    </BrowserRouter>

  )
}

export default App