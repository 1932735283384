import React from 'react'

const Team = () => {
  return (
    <section>
    <div className="container mrg_b">
        
        {/* <!-- ============  Heading  ============ --> */}
        <div className="row wow flash">
            <div className="heading d-flex justify-content-center align-items-center mrg_tb">
                <div className="right_line">
                    <div className="Line200 RGreen"></div>
                    <div className="Line100 ROrg ms-auto"></div>
                </div>
                <h2>MEET THE TEAM</h2>
                <div className="left_line">
                    <div className="Line200 LGreen"></div>
                    <div className="Line100 LOrg"></div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center my-3 my-lg-0 wow fadeInDown" data-wow-delay="1.0s">
                <img src="/images/1-Zoey Zenith - Team img.png" alt="image of Zoey Zenith who member of ZK Realm" className="img-fluid" draggable="false" />
                <div className="TeamName mt-2">Felix</div>
                <div className="TeamDesi">Founder</div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center my-3 my-lg-0 wow fadeInDown" data-wow-delay="1.2s">
                <img src="/images/2-Luminate - Team img.png" alt="image of Luminate who member of ZK Realm" className="img-fluid" draggable="false" />
                <div className="TeamName mt-2">Luminate</div>
                <div className="TeamDesi">Blockchain Developer</div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center my-3 my-lg-0 wow fadeInDown" data-wow-delay="1.4s">
                <img src="/images/3-Steven - Team img.png" alt="image of Steven who member of ZK Realm" className="img-fluid" draggable="false" />
                <div className="TeamName mt-2">Steven</div>
                <div className="TeamDesi">Community Lead</div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex flex-column justify-content-center align-items-center my-3 my-lg-0 wow fadeInDown" data-wow-delay="1.6s">
                <img src="/images/4-Felix - Team img.png" alt="image of Felix who member of ZK Realm" className="img-fluid" draggable="false" />
                <div className="TeamName mt-2">Zoey Zenith</div>
                <div className="TeamDesi">2D & 3D Artist</div>
            </div>
        </div>

    </div>
</section>
  )
}

export default Team