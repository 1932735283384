import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { getLeaderBoard, getRank } from '../api'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

const LeaderBoard = () => {
    const [userList, setUserList] = useState([])
    const [rankDetails, setRankDetails] = useState({})
    const { user } = useSelector(state => state.user)
    useEffect(() => {
        (async () => {
            try {
                const { data } = await getLeaderBoard();
                const { data: res } = await getRank();
                setUserList(data.data);
                setRankDetails(res.data)
            } catch (error) {
                toast.error("Network error")
            }
        })()
    }, [])

    return (
        <>
            <Navbar></Navbar>
            <section className="mrg_t">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 wow fadeInDown" data-wow-delay="1s">
                            <div className="cusTable p-4">
                                <div className="LeadHead">Rank :</div>
                                <h1 className="LeadData mt-2 text-start">{rankDetails.rank}</h1>
                            </div>
                        </div>
                        <div className="col-lg-4 my-4 my-lg-0 wow fadeInDown" data-wow-delay="1.2s">
                            <div className="cusTable p-4">
                                <div className="LeadHead">Wallet Address :</div>
                                <h1 className="LeadData mt-2 text-start">{rankDetails?.walletAddress?.slice(0, 4)}...{rankDetails.walletAddress?.slice(-4)}</h1>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeInDown" data-wow-delay="1.4s">
                            <div className="cusTable p-4">
                                <div className="LeadHead">Total Points :</div>
                                <h1 className="LeadData mt-2 text-start">{rankDetails.points}</h1>
                            </div>
                        </div>
                        <div className="col-12 mt-4 wow fadeInDown" data-wow-delay="2s">

                            <div className="cusTable">

                                <div className="fs-theme cl-M">
                                    <h2 className="cl-M p-4">Leaderboard</h2>
                                </div>

                                <table className="w-100">

                                    <thead>
                                        <tr className="TableHead">
                                            <th>Rank</th>
                                            <th>Wallet Address</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {
                                            userList.map((user1, index) => {
                                                return (
                                                    <tr className={user.walletAddress == user1?.walletAddress ? "TableHead TableRow":`TableRow`}>
                                                        <td>{index + 1}</td>
                                                        <td>{user1?.walletAddress?.slice(0, 7)}...{user1?.walletAddress?.slice(-7)}</td>
                                                        <td>{user1.points}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default LeaderBoard