import React from 'react'

const FaQ = () => {
    return (
        <section>
            <div className="container">
                {/* <!-- ============  Heading  ============ --> */}
                <div className="row wow flash mrg_tb">
                    <div className="heading d-flex justify-content-center align-items-center">
                        <div className="right_line">
                            <div className="Line200 RGreen"></div>
                            <div className="Line100 ROrg ms-auto"></div>
                        </div>
                        <h2>FAQ</h2>
                        <div className="left_line">
                            <div className="Line200 LGreen"></div>
                            <div className="Line100 LOrg"></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-1"></div>

                    <div className="col-10 col-sm-12 col-md-10">
                        <div className="accordion accordion-flush" id="accordionFlushExample">

                            <div className="accordion-item wow fadeInRight" data-wow-delay="0.5s">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <div className="accordion-button collapsed fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        <p className="cl-t">What is Zkrealm?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        {/* <!-- • --> */}
                                        ZK-Realm is a community-driven experimental project built on the zkSync blockchain, offering gamified rewards through its NFT collection called ZRUNDEADS.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="0.6s">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                        aria-controls="flush-collapseTwo">
                                        <p className="cl-t" >What is ZkSync blockchain?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        ZkSync is a layer 2 scaling solution for Ethereum that utilizes zero-knowledge proofs to enhance transaction throughput and reduce gas fees. Zkrealm leverages ZkSync's capabilities for its NFT project.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="0.8s">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                        aria-expanded="false" aria-controls="flush-collapseThree">
                                        <p className="cl-t">When is the mint date ?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        To Be Determined
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="0.9s">
                                <h2 className="accordion-header" id="flush-headingfour">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false"
                                        aria-controls="flush-collapsefour">
                                        <p className="cl-t">What is the Daily Check-In feature ?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapsefour" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        The Daily Check-In feature on ZKRealm allows you to earn ZR Points by visiting our site and checking in once every 24 hours. The more consecutive days you check in, the better the rewards!
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="1s">
                                <h2 className="accordion-header" id="flush-headingfive">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false"
                                        aria-controls="flush-collapsefive">
                                        <p className="cl-t">What is the ZKRealm Task Feature?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapsefive" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        The ZKRealm Task Feature allows you to engage with the ZKRealm community and earn ZR Points by completing simple tasks. These tasks help you stay connected, spread the word about ZK-Realm, and enjoy exclusive benefits.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="1.1s">
                                <h2 className="accordion-header" id="flush-headingSix">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false"
                                        aria-controls="flush-collapseSix">
                                        <p className="cl-t">What is the Spin feature?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        The Spin feature allows you to use your ZR Points to spin a wheel and win exciting rewards, such as exclusive NFTs, points multipliers, whitelist spots, and more.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="1.2s">
                                <h2 className="accordion-header" id="flush-Seven">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false"
                                        aria-controls="flush-collapseSeven">
                                        <p className="cl-t">What makes ZRUNDEADS unique?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseSeven" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        ZRUNDEADS are unique digital collectibles with distinct attributes and characteristics. Each token represents a one-of-a-kind piece of digital art within the ZK-Realm collection, making them highly sought after by collectors and enthusiasts.
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item wow fadeInRight" data-wow-delay="1.3s">
                                <h2 className="accordion-header" id="flush-Eight">
                                    <div className="accordion-button collapsed  fw-Med cl-t" type="button"
                                        data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false"
                                        aria-controls="flush-collapseEight">
                                        <p className="cl-t">Where can I find more information about ZK-Realm and ZRUNDEADS?</p>
                                    </div>
                                </h2>
                                <div id="flush-collapseEight" className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body content  content_res">
                                        For more detailed information, please visit our whitepaper at <a href="https://zkrealm.gitbook.io/whitepaper" target="_blank" className='cl-M'>https://zkrealm.gitbook.io/whitepaper.</a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default FaQ