import React from 'react'

const Footer = () => {
  return (
    <>
        <footer>
    <div className="footBg mrg_t">
        <div className="container">

            <div className="row">
                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center my-5">

                    <a href="#" className="mb-3 mb-lg-0 wow fadeIn" data-wow-delay="0.5s"><img src="/images/Logo.png" alt="Logo of Meta Beer Bottle" draggable="false" className="img-fluid logo" /></a>


                    <nav className="ms-lg-auto mb-5 mb-lg-0 mt-3 mt-lg-0">
                        <ul className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                            <li className="nav py-2 wow fadeIn" data-wow-delay="0.6s"><a href="/" className="navFootItem NavTxt ">Home</a></li>
                            <li className="nav py-2 wow fadeIn" data-wow-delay="0.7s"><a href="/privacy" className="navFootItem NavTxt ">Privacy & Policy</a></li>
                            <li className="nav py-2 wow fadeIn" data-wow-delay="0.8s"><a href="/terms" className="navFootItem NavTxt ">Terms & Conditions</a></li>
                        </ul>
                    </nav>
                    <div className="socialMedia ms-3 d-flex justify-content-center align-items-center">

                        <a href="https://discord.gg/z5RXv9eB5U" target='_blank'>
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.1s">
                                <img src="/images/discord.png" alt="Discord Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false" />
                                <img src="/images/white_discord.png" alt="Discord Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false" />
                            </div>
                        </a>
                        {/* <a href="#">
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.2s">
                                <img src="/images/telegram.png" alt="Telegram Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false"  />
                                <img src="/images/white_telegram.png" alt="Telegram Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false" />
                            </div>
                        </a>
                        <a href="#">
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.3s">
                                <img src="/images/linkedin.png" alt="Linkedin Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false"/>
                                <img src="/images/white_linkedin.png" alt="Linkedin Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false"/>
                            </div>
                        </a>
                        <a href="#">
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.4s">
                                <img src="/images/twitter.png" alt="Twitter Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false"/>
                                <img src="/images/white_twitter.png" alt="Twitter Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false"/>
                            </div>
                        </a>
                        <a href="#">
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.5s">
                                <img src="/images/facebook.png" alt="Facebook Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false" />
                                <img src="/images/white_facebook.png" alt="Facebook Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false" />
                            </div>
                        </a>
                        <a href="#">
                            <div className="socialMainHvr wow fadeIn" data-wow-delay="1.6s">
                                <img src="/images/instagram.png" alt="Instagram Icon" className="img-fluid socialIcon socialBlackIcon" draggable="false" />
                                <img src="/images/white_instagram.png" alt="Instagram Icon" className="img-fluid socialIcon socialWhiteIcon" draggable="false" />
                            </div>
                        </a> */}
                    </div>

                </div>
            </div>
            <hr/>
            <div className="copyrights overReg text-center my-4">Copyright © 2024 ZK Realm. All Rights Reserved.</div>

        </div>
    </div>

</footer>
    </>
  )
}

export default Footer